@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  scroll-behavior: smooth;
}

@media (max-width: 420px) {
  .app {
    overflow-x: hidden;
  }

}

body {
  font-family: "Poppins", sans-serif;
}

button {
  font-family: "Poppins", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.App > main {
  flex: 1;
}

/* Header Page */

header {
  width: 100vw;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
}

.headerContainer {
  max-width: 1200px;
  padding: 1em;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-family: "Mate SC", serif;
}

.brand a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: black;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
  list-style: none;
}

.navLinks a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.navLinks a:hover {
  color: rgb(245, 170, 32);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 420px) {

  .headerContainer {
    padding: 1em .5em;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    background: white;
    width: 100%;
  }
  .navLinks {
    margin-top: 0.5em;
    justify-content: space-between;
    gap: 1em;
  }

  .navLinks a {
    font-size: 1rem;

  }
}



/* Hero Section */

.heroOuterContainer {
  position: relative;
  padding: 7em 4em;
  min-height: 95vh;
  background: #808080;
  background: -webkit-linear-gradient(to right, #3fada8, #808080); 
  background: linear-gradient(to right, #3fada8, #808080); 
  overflow: hidden;
}

.heroContentContainer {
  color: white;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.heroContent {
  position: relative;
  z-index: 1;
  max-width: 85ch;
  text-align: left;
}

.heroButtonsContainer {
  display: flex;
  gap: 1em;
}

.heroContent .viewProjectsButton,
.connectButton {
  padding: 0.7em 1em;
  margin-top: 1em;
  border: none;
  border-radius: 10px;
  background: rgb(245, 170, 32);
  text-transform: uppercase;
  font-weight: 600;
}

.heroContent .viewProjectsButton {
  background: rgb(245, 245, 245);
}

.viewProjectsButton span {
  display: flex;
  align-items: center;
  gap: .5em;
}

.heroContent .viewProjectsButton:hover,
.connectButton:hover {
  background: rgba(245, 171, 32, 0.791);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.heroContent .viewProjectsButton a,
.connectButton a {
  color: black;
  text-decoration: none;
  gap: 0.5em;
}

.connectButton span {
  display: flex;
  align-items: center;
  gap: .5em;
}

.heroContent h2 {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.1ch;
}

.heroContent h2 span {
  font-size: 1.5rem;
  font-weight: 300;
}

.heroContent h2 .nameSpan {
  font-size: 4.5rem;
  color: rgb(245, 170, 32);
  font-weight: 500;
  margin: 0;
}

.heroContent > p {
  font-size: 1.5rem;
  color: rgb(228, 228, 228);
  margin: 1em 0;
  max-width: 70ch;
  font-weight: 300;
}

.hero__vector3 {
  display: absolute;
  left: 1250px;
  bottom: 0;
}


@media (min-width: 1000px) {
  .hero__vector3 {
    left: 1150px;
  }
}

@media (max-width: 420px) {
  .heroOuterContainer {
    padding: 2em 1em;
    margin-top: 5em;
    min-height: 55vh;
    display: grid;
    place-items: center;
  }
  .heroContentContainer {
    max-width: 100vw;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .heroContent {
    text-align: left;
    justify-content: space-between;
    padding: 1em;
  }
  .heroContent h2 {
    font-size: 1.2rem;
    margin-bottom: 1em;
    letter-spacing: 0.1ch;
  }

  .nameSpan {
    font-size: 2.5rem;
  }

  .heroContent h2 span {
    font-size: 1.4rem;
  }

  .heroContent p {
    font-size: 1rem;
    margin-bottom: 1em;
    letter-spacing: 0.1ch;
  }
}

@media (min-width: 1500px) {
  .heroContentContainer {
    color: white;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .heroContent h2 {
    font-size: 2.5rem;
    margin-bottom: 1em;
    font-weight: 300;
    letter-spacing: 0ch;
  }

  .heroContent h2 span {
    font-size: 2.5rem;
    font-weight: 300;
  }

  .heroContent h2 .nameSpan {
    font-size: 2.5rem;
    color: rgb(245, 170, 32);
    font-weight: 500;
  }

  .heroContent p {
    font-size: 2rem;
    margin: 1em 0;
    max-width: 70ch;
    font-weight: 300;
  }

  .hero__vector3 {
    display: absolute;
    left: 1450px;
    bottom: 0;
  }
}

/* Projects Page */

.projectsOuterContainer {
  width: 100vw;
  min-height: 85vh;
  margin-top: 0.5em;
  background: rgb(245, 245, 245);
  padding: 1em;
}

.projectsTitle {
  text-align: center;
  margin:  0;
}

.projectsContainer {
  max-width: 1200px;
  padding: 2em;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2em;
}

.project {
  text-align: left;
  box-shadow: 0px 0px 3px rgba(0, 0, 0.3);
  overflow: hidden;
  border-radius: 10px;
}

.project h4 span {
  font-weight: 300;
  font-size: 1rem;
}

.project img {
  min-width: 100%;
  height: 150px;
  object-fit: stretch;
}

.projectsInfo {
  padding: 1em;
}

.projectsTags span {
  margin-left: 0.5em;
  font-weight: 100;
}

.projectsTags .tag {
  padding: 0.2em 0.6em;
  background: rgb(245, 170, 32);
  border-radius: 10px;
  font-weight: 300;
  font-size: 0.8rem;
}

.project .tags-container {
  margin: 1em 0 1em;
}

.link {
  padding: 0.4em 0.6em;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  background: rgb(225, 225, 225);
  font-size: 0.8rem;
}

.link:hover {
  background: rgb(219, 219, 219);
  transition: all 0.3s ease-in-out;
}

.link:nth-of-type(1) {
  margin-right: 0.5em;
}

@media (max-width: 420px) {
  .projectsOuterContainer {
    margin-top: 6em;
  }
  .projectsInfo h4 {
    margin-bottom: 0.3em;
  }
}

/* About Page */

.aboutOuterContainer {
  min-width: 100vw;
  min-height: 85vh;
  background: rgb(245, 245, 245);
}

.aboutContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1em;
}

.aboutTitle {
  margin: 0 auto;
}

.aboutContentContainer {
  flex: 1;
  padding:  0;
  text-align: left;
  line-height: 2;
}

.aboutResources {
  margin-top: 1em;
  padding: 1em 0;
}

.aboutResources h3 {
  text-decoration: underline;
}

.aboutResources ul {
  list-style: none;
}

.aboutResources ul li a {
  color: blue;
}


.about-link {
  color: black;
  text-decoration: none;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 10px;
  background: rgb(245, 170, 32);
}

.about-link:hover {
  background: rgba(245, 171, 32, 0.791);
  transition: all 0.3s ease-in-out;
}

.aboutImageContainer {
  flex: 1;
  padding: 1em;
  text-align: right;
}

.aboutContainer img {
  max-width: 80%;
  border-radius: 20px;
}

@media (max-width: 420px) {
.aboutOuterContainer {
  margin-top: 6em;
}
  .aboutContainer {
    flex-direction: column;
  }

  .aboutTitle {
    text-align: center;
  }

  .aboutImageContainer {
    text-align: center;
  }

  .aboutContentContainer {
    width: 300px;
  }
}

@media ( max-width:900px) {
  .about__profile-img {
    display: none
  }

  .aboutImageContainer {
    display: none;
  }

  .aboutContainer {
    padding: 1em 4em;
  }
}

/* Skills Component */

.skillsOuterContainer {
  padding: 2em 0;
  margin: 0 auto;
  max-width: 100vw;
  
}

.stackIconsContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-gap: 2em;
  padding: 2em;
}

.stackIconsContainer__skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stackIconsContainer__skill p {
  color: rgb(105, 105, 105);
  text-align: center;
}

.stackIconsContainer .skillIcon {
  min-width: 50px;
  height: 50px;
  margin-bottom: 0.5em;
}

.skillsTitle {
  position: relative;
  max-width: max-content;
  display: block;
  margin: 0 auto 1em;
}

.skillsTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 180px;
  height: 5px;
  background: rgb(245, 170, 32);
}

@media (max-width: 420px) {
  .stackIconsContainer {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .skillsTitle {
    font-size: 2rem;
    margin-bottom: 0.5em;
  }
  .stackIconsContainer .skillIcon {
    min-width: 50px;
    height: 50px;
  }
}

/* PopularProjects Component */

.popularProjectsOuterContainer {
  padding: 2em 0;
  margin: 0 auto;
  max-width: 100vw;
  background: rgb(245, 245, 245);
  font-family: "Poppins", sans-serif;
}

.popularProjectsContainer {
  max-width: 1200px;
  padding: 2em;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  grid-gap: 2em;
  justify-content: space-evenly;
}

.popularProjectsTitle {
  position: relative;
  max-width: max-content;
  display: block;
  margin: 0 auto 1em;
}

.popularProjectsTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom:0;
  width: 265px;
  height: 5px;
  background: rgb(245, 170, 32);
}

.popularProjectsButton {
  border: none;
  border-radius: 10px;
  background: rgb(245, 170, 32);
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  font-family: inherit;
}

.popularProjectsButton a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: .3em .6em;

}

.popularProjectsButton:hover {
  background: rgba(245, 171, 32, 0.791);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* CallToAction Component */

.CallToActionOuterContainer {
  position: relative;
  width: 100vw;
  padding: 2em 0;
  background: black;
  color: white;
  background: url("https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
}

.CallToActionOuterContainer .overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.CallToActionContent {
  position: relative;
  text-align: center;
}

.CallToActionContent h1 {
  margin-bottom: 1em;
}

.callToActionButton {
  padding: 0.5em 1.2em;
  border: none;
  border-radius: 10px;
  background: rgb(245, 170, 32);
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.callToActionButton a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.callToActionButton:hover {
  background: rgba(245, 171, 32, 0.791);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 420px) {
  .CallToActionContent h1 {
    font-size: 1.5rem;
  }

  .callToActionButton a {
    font-size: 0.8rem;
  }
}

/* Footer Component */

.footerOuterContainer {
  background: url("https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1355&q=80");
  background-size: cover;
  padding: 1em;
  width: 100vw;
}

.contactSocialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.contactSocialContainer .icon {
  color: rgb(245, 245, 245);
  font-size: 30px;
}

.contactSocialContainer .icon:hover {
  color: rgb(245, 170, 32);
  transition: all 0.3s ease-in-out;
}

.copyright {
  text-align: center;
  color: rgb(245, 245, 245);
}

/* Services Section */

.servicesOuterContainer {
  padding: 2em 0;
  text-align: center;
  position: relative;
  width: 100%;
}

.servicesTitle {
  position: relative;
  width: max-content;
  margin: 0 auto 1em;
  font-size: 2rem;
}

.servicesTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 142px;
  height: 5px;
  background: rgb(245, 170, 32);
}

.servicesContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 2em;
  padding: 2em;
}

.service {
  text-align: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  padding: 1.5em;
  background: rgb(245, 245, 245);
  border-radius: 10px;
  transform: scale(1);
}
.service:hover {
  animation: float 1.2s ease-in-out infinite alternate;
  cursor: default;
}

.service h4 {
  margin: 1em 0;
}

.service p {
  color: rgb(105, 105, 105);
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-16px) translateX(0px);
  }
}

@media (max-width: 420px) {
  .servicesContainer {
    display: flex;
    flex-direction: column;
  }
}

/* Contact Page */

.contactOuterContainer {
  width: 100vw;
  min-height: 85vh;
  padding: 2em 1em;
  background: rgb(245, 245, 245);
}

.contactTitle {
  max-width: 1200px;
  margin: 0 auto;
}

.contactContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}

.contactOuterContainer form {
  flex: 1;
  border-radius: 5px;
  padding: 2em 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  background: white;
  text-align: center;
}

.contactOuterContainer form .formSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 1em auto;
}

.contactOuterContainer form .formSection label {
  font-size: 1.2rem;
}

.contactOuterContainer form button {
  padding: 0.5em 1em;
  text-transform: uppercase;
  border-radius: 5px;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
  background: rgb(245, 170, 32);
}

.contactOuterContainer form button:hover {
  background: rgba(245, 171, 32, 0.791);
  transition: all 0.3s ease-in-out;
}

.contactOuterContainer form .formSection input,
textarea {
  padding: 0.5em 1em;
  border-radius: 5px;
  border: 1px solid black;
  width: 300px;
}

.contactOuterContainer a {
  color: red;
}

.contactImageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}

.contactImageContainer a {
  display: block;
  margin: 0 10em;
}

.contactImageContainer img {
  width: 70%;
}

.contactPageNoteContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.contactPageNoteContainer p {
  max-width: 60ch;
  padding: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background: white;
}

.contactIconContainer {
  display: inline-block;
  align-items: center;
}

.contactIconContainer .contact-icon {
  color: black;
  font-size: 30px;
}
.contactIconContainer .contact-icon:hover {
  color: rgb(245, 170, 32);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 420px) {
  .contactOuterContainer {
    margin-top: 6em;
    max-width: 415px;
    padding: 0;
  }
  .contactOuterContainer form .formSection {
    max-width: 300px;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
  }

  .contactOuterContainer form {
    max-width: 100%;
    margin: 0 auto 1em;
  }

  .contactOuterContainer form .formSection label {
    font-size: 1rem;
  }

  .contactImageContainer {
    display: none;
  }

  .contactTitle {
    text-align: center;
    padding: .5em;
  }

  .contactIconContainer {
    display: none;
  }
}


@media (max-width:900px) {
  .contactImageContainer {
    display: none;
  }

  .contactOuterContainer form {
    margin-bottom: 1em;
  }

  .contactPageNoteContainer  p{
    display: block;
    margin: 0 auto;
    width: 100vw;
  }

  .contactIconContainer {
    display: none;
  }
}


/* Blog Component */

.blog__outerContainer {
  width: 100vw;
  padding: 2em;
  min-height: 85vh;
  background: rgb(245, 245, 245);
  text-align: center;
}

.blog_posts-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em 0;
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-gap: 1em;
}

.blog__post {
  border: 1px solid gray;
  background: white;
  border-radius: 10px;
  overflow: hidden;
}

.blog__post span h3 {
  text-align: center;
  padding: 0.5em 0;
  color: black;
}

.blog__post a {
  text-decoration: none;
}

.blog__post img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 420px) {
  .blog_posts-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 820px) {
  .blog_posts-container {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    justify-content: space-evenly;
  }

}

/* BlogPost Component */

.blogpost__outerContainer {
  width: 100vw;
  padding: 2em;
  background: rgb(245, 245, 245);
}

.blogpost__topSection {
  max-width: 1000px;
  min-height: 500px;
  margin: 0 auto;
  position: relative;
}

.blogpost__info-container {
  display: flex;
  background-color: white;
  align-items: center;
  gap: 1em;
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 3em;
  

}

.blogpost__author-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.blogpost__mainImage {
  min-width: 100%;
  max-height: 100%;
  aspect-ratio: 16/9;
  position: absolute;
}

.blogpost__bodyContainer {
  max-width: 1000px;
  margin: 0 auto;
  background: white;
  padding: 1em;
}

.blogpost__bodyContainer h2 {
  text-align: center;
}

.blogpost__body {
  height: max-content;
}

.blogpost__body img {
  max-width: 100%;
}

@media (max-width: 420px) {
  .blog__outerContainer {
    margin-top: 6em;
  }

  .blog_posts-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.resume {
  /* display: block; */
  margin: .5em 0;
}


@media (max-width: 420px) {
  .resume {
    display: none;
  }
}